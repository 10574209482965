const form = document.getElementById('wunschwohnung-form');

if (form) {
    const requiredFields = form.querySelectorAll('[required]');

    form.onsubmit = e => {
        e.preventDefault();

        requiredFields.forEach(input => {
            if (input.value == "") {
                input.classList.add('is-invalid');
            } else {
                input.classList.remove('is-invalid');
            }
        })

        form.querySelector('button[type=submit]').disabled = true;

        let data = new FormData(form);
        let xhr = new XMLHttpRequest();

        xhr.onload = () => {
            if (xhr.response) {
                form.innerHTML = `<h2>Vielen Dank, ${ data.get('title') + ' ' + data.get('lastname') }</h2>
                <p>Wir haben Ihre Anfrage zur Wunschwohnung erhalten. In den nächsten Minuten erhalten Sie eine E-Mail mit Ihren eingebenen Wunschkriterien.</p>`
            }
        };

        xhr.onerror = () => {
            alert('Beim Senden Ihrer Anfrage ist leider ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut.');
        }
        
        xhr.open('POST', form.action);
        xhr.send(data);
    };
}