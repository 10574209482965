import jQuery from 'jquery';
import "bootstrap-select";

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) === false) {

    jQuery('#apartment-search-form select').each(function() {

        jQuery(this)
            .removeClass('custom-select')
            .addClass('form-control')
            .selectpicker({
                container: 'body',
                mobile: false,
                style: '',
                styleBase: 'form-control',
                width: 'auto'
            });        
    
    });

}