import $ from 'jquery';

let $navbar = $('nav.navbar'),
    $nav = $navbar.find('.navbar-nav').clone(),
    $offcanvas = $('<nav id="offcanvas-menu"></nav>'),
    $navbarLogo = $navbar.find('.navbar-brand img').clone(),
    $logo = $('<div class="logo" />');

$logo.append($navbarLogo);
$logo.appendTo($offcanvas);

$nav.prop('class', 'nav flex-column');

// insert caret in each toggle link
$nav.find('ul.nav-submenu').each((i, el) => {
    $(el).prev('a').append('<span class="caret"></span>');
});

$offcanvas.on('click', 'li.parent > a.nav-link', e => {

    e.stopPropagation();
    e.preventDefault();

    let $link = $(e.target).closest('a.nav-link'),
        $menu = $link.next('.submenu'),
        $li = $link.parent();

    $li.toggleClass('open');

});

$nav.appendTo($offcanvas);

$offcanvas.insertAfter($navbar);

$navbar.find('.navbar-toggler').click(e => {
    $('body').toggleClass('offcanvas-open');
});

$(document).click(e => {

    if(!$(e.target).hasClass('navbar-toggler') && !$(e.target).parent().hasClass('navbar-toggler') && $(e.target).closest('#offcanvas-menu').length == 0) {
        $("body").removeClass('offcanvas-open');
    }

});