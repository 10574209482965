const navbar = document.querySelector('.navbar'),
      navbarContainer = navbar.querySelector('.container'),
      navbarLogo = document.querySelector('.navbar .navbar-brand img'),
      header = document.querySelector('header.header-main')
      headerGradient = document.querySelector('header.header-main .gradient');

let scrollHandler = e => {
    navbarContainer.style.paddingTop = Math.max(0.5, (1.25 - (window.scrollY/500))) + 'rem';
    navbarContainer.style.paddingBottom = Math.max(0.5, (1.25 - (window.scrollY/500))) + 'rem';
    navbarLogo.style.maxHeight = Math.max(60, Math.round(90 - (window.scrollY/20))) + 'px';
    header.style.backgroundPosition = 'center bottom -' + (window.scrollY/2) + 'px';
    headerGradient.style.transform = 'translateY(' + (window.scrollY/2) + 'px)';

};

window.onscroll = e => { 
    scrollHandler();
}

scrollHandler();

// const scrollReveal = entries => {
//     entries.forEach(entry => {
//         let el = entry.target,
//             animation = 'animate__' + el.dataset.animateIn,
//             delay = el.dataset.animateDelay ? el.dataset.animateDelay : 0;

//         if (entry.isIntersecting) {
//             window.setTimeout(() => {
//                 el.classList.add('animate__animated');
//                 el.classList.add(animation);
//                 el.style.animationDelay = delay + 'ms';
//             }, 0);
//         } else {
//             el.classList.remove('animate__animated');
//             el.classList.remove(animation);
//         }
//     })
// }

// const observer = new IntersectionObserver(scrollReveal, {
//     //threshold: .5
//     // rootMargin: '10px',
//     // threshold: -.01
// });

// document.querySelectorAll('*[data-animate-in]').forEach(el => observer.observe(el));