const form = document.getElementById('inquiry-form');

if (form) {
    const submitButton = form.querySelector('button');

    form.onsubmit = e => {
        e.preventDefault();

        submitButton.innerText = 'Bitte warten...';
        submitButton.disabled = true;

        var fd = new FormData(form),
            xhr = new XMLHttpRequest();

        xhr.open('POST', document.baseURI + 'wohnungen/contact');
        xhr.responseType = 'json';
        xhr.onload = () => {
            const data = xhr.response;

            if (data && data.success) {
                form.innerHTML = `<div class="h3">Vielen Dank, ${ fd.get('firstname') } ${ fd.get('lastname') }.</div>
                    <p>Wir haben Ihre Objektanfrage erhalten und bemühen uns schnellstmöglich um eine Rückantwort.</p>`;
            } else {
                alert('Beim Senden Ihrer Anfrage ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.')
            }
        }
        xhr.send(fd);


    };

}