import "../scss/index.scss";

// require('intersection-observer');

import 'jquery';
import 'bootstrap';

import "./sliders";
import "./offcanvas";
import "./parallax";
import "./apartmentForm";
import "./apartmentImages";
import "./guestApartments";
import "./wunschwohnung";
import "./timeline";
import "./apartmentSearch";

import WOW from 'wow.js';

var wow = new WOW({
    animateClass: 'animate__animated',
    offset: 150
});
wow.init();