import Colcade from 'colcade';

const timelines = document.querySelectorAll('.timeline');
const opt = {
    columns: '.timeline-col',
    items: '.timeline-entry'
};

timelines.forEach(timeline => {
    let colcade = false;

    if (window.outerWidth >= 992) {
        colcade = new Colcade(timeline, opt);
    }

    window.onresize = e => {
        if (window.outerWidth >= 992 && colcade == false) {
            colcade = new Colcade(timeline, opt);
            
        } else if (window.outerWidth < 992) {
            colcade.destroy();
            colcade = false;
        }
    }
})
