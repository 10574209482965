import $ from 'jquery';
import 'jquery-date-range-picker';
import moment from 'moment';

const jQuery = $;

const ajaxBase = document.baseURI + 'ajax/guest_apartments/';

$(".apartment").each(function() {

    var $apartment = $(this),
        apartmentId = $apartment.data('apartmentId'),
        $input = $apartment.find('input.booking-date-input'),
        $calendarContainer = $apartment.find('.booking-calendar'),
        datePicker,
        occupancies = [],
        datePickerStart = moment().startOf('month'),
        datePickerEnd = moment().add(1, 'month').endOf('month').add(1, 'day'),
        $bookingForm = $apartment.find('form.booking-form');

    function getOccupancies(start, end, callback) {

        $.get(ajaxBase + 'occupancies', {
            start: start.format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD'),
            apartment: apartmentId
        }, function(result) {
            occupancies = result;

            $input.data('dateRangePicker').redraw();

            if (typeof callback == "function")
                callback();

        }, 'json');

    };

    datePicker = $input.dateRangePicker({
        inline:true,
        alwaysOpen:true,
        container: $calendarContainer,
        showShortcuts: false,
        stickyMonths: true,
        showTopbar: false,
        language: 'de',
        startOfWeek: 'monday',
        hoveringTooltip: false,
        selectForward: true,
        singleMonth: false,
        startDate: moment(),
        width: '100%',
        customArrowPrevSymbol: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"/></svg>',
        customArrowNextSymbol: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>',
        beforeShowDay: function(date) {

            var valid = true,
                _class = '',
                _tooltip = '',
                type = occupancies[moment(date).format('YYYYMMDD')];

            if (type) {
            
                valid = false;

                if (type == 1 || type == 0) {
                    _class = 'booked';
                    _tooltip = 'Gästewohnung belegt';
                } else if (type == 2) {
                    _class = 'disabled';
                    _tooltip = 'Hier kann leider nicht gebucht werden';
                } else if (type == 0) {
                    _class = 'reserved';
                    _tooltip = 'Gästewohnung reserviert';
                }

            }

            return [valid,_class,_tooltip];

        }
    });

    $calendarContainer.find('span.prev, span.next').click(function(e) {

        if (this.className == 'prev' && datePickerStart.isBefore(moment())) {
            return;
        }

        if (this.className == 'next') {
            datePickerStart.add(1, 'month');
            datePickerEnd.add(1, 'month');
        } else {
            datePickerStart.subtract(1, 'month');
            datePickerEnd.subtract(1, 'month');
        }

        getOccupancies(datePickerStart, datePickerEnd);

    });

    $bookingForm.find('input[name=start], input[name=end]').on('change', function() {

        var start = $bookingForm.find('input[name=start]').val(),
            end = $bookingForm.find('input[name=end]').val();

        $bookingForm
            .find('input[name=start]')
            .closest('.col-sm-9')
            .find('.error')
            .remove()
            .closest('.form-group.has-error')
            .removeClass('has-error');

        $input.data('dateRangePicker').clear();
        $bookingForm.find('button[type=submit]').prop('disabled', true).addClass('btn-disabled');

        $bookingForm.find('input[name=start]').closest('.form-group').removeClass('has-error');

        if (start && end) {

            start = moment(start, 'DD.MM.YYYY'),
            end = moment(end, 'DD.MM.YYYY'),
            today = moment().startOf('day');

            // if either start or end is invalid
            if ( !start.isValid() || !end.isValid() ) {
                $bookingForm
                    .find('input[name=start]')
                    .closest('.col-sm-9')
                    .append('<div class="help-block text-danger error">Bitte geben Sie einen gültigen Buchungszeitraum ein.</div>')
                    .closest('.form-group')
                    .addClass('has-error');
                return false;
            }

            var today = moment();

            // if either start or end date is in the past
            if ( start.isBefore(today) || end.isBefore(today) ) {
                $bookingForm
                    .find('input[name=start]')
                    .closest('.col-sm-9')
                    .append('<div class="help-block text-danger error">Bitte geben Sie ein Buchungsdatum in der Zukunft ein.</div>')
                    .closest('.form-group')
                    .addClass('has-error');
                return false;
            }

            // check in the database, if dates are occupied
            $.get(ajaxBase + 'occupancies', {
                start: start.format('YYYY-MM-DD'),
                end: end.format('YYYY-MM-DD'),
                apartment: apartmentId
            }, function(result) {
                
                // if we got results, dates are occupied
                if (Object.keys(result).length > 0) {

                    $bookingForm
                        .find('input[name=start]')
                        .closest('.col-sm-9')
                        .append('<div class="help-block text-danger error">Dieser Buchungszeitraum ist leider bereits belegt.</div>')
                        .closest('.form-group')
                        .addClass('has-error');

                    return false;

                }

                var monthStart = start.clone().startOf('month'),
                    monthEnd = monthStart.clone().add(2, 'months');

                getOccupancies(monthStart, monthEnd, function() {

                    $input.data('dateRangePicker').setDateRange(
                        start.format('YYYY-MM-DD'),
                        end.format('YYYY-MM-DD'),
                        false
                    );

                });                  

            }, 'json');              

        }

    });

    datePicker.bind('datepicker-change', function(event,obj) {
        $bookingForm.find('input[name=start]').val(moment(obj.date1).format('DD.MM.YYYY'));
        $bookingForm.find('input[name=end]').val(moment(obj.date2).format('DD.MM.YYYY'));
        $bookingForm.find('button[type=submit]').prop('disabled', false).removeClass('btn-disabled');

        $bookingForm.find('.chosen-date-display').html(moment(obj.date1).format('DD.MM.YYYY') + ' <span class="mx-3">bis</span> ' + moment(obj.date2).format('DD.MM.YYYY'));
        
        $bookingForm
            .find('input[name=start]')
            .closest('.col-sm-9')
            .find('.error')
            .remove();
        $bookingForm
            .find('input[name=start]')
            .closest('.form-group.has-error')
            .removeClass('has-error');
    });

    $bookingForm.submit(function(e) {

        e.preventDefault();

        $bookingForm.find('.is-invalid').removeClass('is-invalid');

        if ($bookingForm.find('input[name=start]').val() == "" || $bookingForm.find('input[name=start]').val() == "") {
            alert('Bitte wählen Sie einen Buchungszeitraum in dem Kalender aus.');
            return;
        }

        if ($bookingForm.find('select[name=salutation]').val() == "") {
            $bookingForm.find('select[name=salutation]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=firstname]').val() == "") {
            $bookingForm.find('input[name=firstname]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=lastname]').val() == "") {
            $bookingForm.find('input[name=lastname]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=street]').val() == "") {
            $bookingForm.find('input[name=street]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=zip]').val() == "") {
            $bookingForm.find('input[name=zip]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=city]').val() == "") {
            $bookingForm.find('input[name=city]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=email]').val() == "") {
            $bookingForm.find('input[name=email]').addClass('is-invalid');
        }

        if ($bookingForm.find('input[name=accept_privacy]').is(':checked') == false) {
            $bookingForm.find('input[name=accept_privacy]').addClass('is-invalid');
        }

        if ($bookingForm.find('.is-invalid').length > 0) {
            return false;
        }

        $apartment.addClass('loading');

        $.post(ajaxBase + 'booking', $bookingForm.serializeArray(), function(result) {

            $bookingForm.after('<h2>Vielen Dank, ' + $bookingForm.find('select[name=salutation]').val() + ' ' + $bookingForm.find('input[name=lastname]').val() + '!</h2>Wir haben Ihre Buchungsanfrage erhalten. Nach einer Prüfung Ihrer Anfrage senden wir Ihnen eine E-Mail mit weiteren Informationen.');
            $bookingForm.remove();
            $calendarContainer.remove();

            var start = moment($bookingForm.find('input[name=start]').val(), 'DD.MM.YYYY'),
                end = moment($bookingForm.find('input[name=end]').val(), 'DD.MM.YYYY');

            while (start.diff(end, 'days') <= 0) {
                occupancies[start.format('YYYYMMDD')] = 1;
                start.add(1, 'days');
            }

            $input.data('dateRangePicker').clear();
            $input.data('dateRangePicker').redraw();

            $apartment.removeClass('loading');


        }).fail(function() {
            alert('Beim Eintagen Ihrer Buchung ist leider ein Fehler aufgetreten. Bitte rufen Sie uns an.');
        });

    });
    
    getOccupancies(datePickerStart, datePickerEnd);

});