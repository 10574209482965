import $ from 'jquery';
import slick from 'slick-slider';

$('.news-slider').each(function() {
    $(this).slick({
        slidesToShow: 3,
        infinite: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 1699,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    });
});

$('.projects-slider').each(function() {
    $(this).slick({
        slidesToShow: 2,
        infinite: true,
        dots: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 1699,
                settings: {
                    arrows: false
                }
            }
        ]
    });
});

$('.apartments-slider').each(function() {
    $(this).slick({
        slidesToShow: 3,
        infinite: true,
        dots: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1499,
                settings: {
                    slidesToShow: 2,
                    adaptiveHeight: false,
                    arrows: false
                }
            },
            {
                breakpoint: 1699,
                settings: {
                    arrows: false
                }
            }
        ]
    });
});

$('.images-slider').each(function() {
    $(this).slick({
        infinite: true,
        dots: true,
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    dots: false
                }
            }
        ]
    });
});

$('#apartment-images-slider').each(function() {
    $(this).slick({
        infinite: true,
        dots: true,
        arrows: false,
        adaptiveHeight: true,
        lazyLoad: 'ondemand'
    });
});